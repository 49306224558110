import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import LicenseForm from './LicenseForm';
import LicenseTable from './LicenseTable';
import getApiBaseUrl from './api';

function Dashboard() {
  const [licenses, setLicenses] = useState([]);
  const [showAddLicense, setShowAddLicense] = useState(false);
  const [editLicenseIndex, setEditLicenseIndex] = useState(null);
  const [selectedLicenses, setSelectedLicenses] = useState([]);
  const [filteredLicenses, setFilteredLicenses] = useState([]);
  const [username, setUsername] = useState('');
  const EMPTY_STATE = {
    customerId: '',
    count: 0,
    startDate: null,
    endDate: null,
    status: 'active',
    meta: []
  };
  const [licenseData, setLicenseData] = useState(EMPTY_STATE);
  const [initialData, setInitialData] = useState(EMPTY_STATE);

  const [filters, setFilters] = useState({
    customerId: '',
    status: ''
  });

  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/login');
    } else {
      try {
        // JWTs are  Base64URL encoded so we need to convert to Base64
        const payloadBase64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
        const payloadJson = atob(payloadBase64);
        const parsedJsonPayload = JSON.parse(payloadJson);
        setUsername(parsedJsonPayload.username);
      } catch (_) {
        setUsername('Unknown');
      }
      fetchLicenses();
    }
  }, [history]);

  useEffect(() => {
    const filterLicenses = () => {
      setFilteredLicenses(licenses.filter((license) =>
        Object.keys(filters).every((key) =>
          filters[key] === '' || license[key]?.toString().includes(filters[key])
        )
      ));
    };

    filterLicenses();
  }, [licenses, filters]);

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${getApiBaseUrl()}/logout`, {}, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
    }
    catch (_) {}
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const handleGoToCustomers = async (e) => {
    e.preventDefault();
    window.location.href = '/customers-dashboard';
  };

  const genericErrorHandler = (error, alertMessage) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      alert('Unauthorized. Please login again.');
      history.push('/login');
    } else {
      if (error.response && error.response.data.message) {
        alertMessage += "\n\n" + error.response.data.message;
      }
      alert(alertMessage);
    }
  };

  const fetchLicenses = async () => {
    try {
      const response = await axios.get(`${getApiBaseUrl()}/api/v1/licenses`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      setLicenses(response.data);
    } catch (error) {
      genericErrorHandler(error, 'Failed to fetch licenses.');
    }
  };

  const handleAddLicense = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${getApiBaseUrl()}/api/v1/licenses`, licenseData, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      setShowAddLicense(false);
      setLicenseData(EMPTY_STATE);
      await fetchLicenses().catch(() => {});
    } catch (error) {
      genericErrorHandler(error, 'Failed to add license.');
    }
  };

  const handleRemoveLicense = async (e, customerId) => {
    e.preventDefault();
    const confirmDelete = window.confirm(`Are you sure you want to delete the customer with ID: '${customerId}'?`);
    if (!confirmDelete) {
      return;
    }

    try {
      await axios.delete(`${getApiBaseUrl()}/api/v1/licenses/${customerId}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      await fetchLicenses().catch(() => {});
    } catch (error) {
      genericErrorHandler(error, 'Failed to remove license.');
    }
  };

  const handleRemoveSelectedLicenses = async (e) => {
    e.preventDefault();
    try {
      await Promise.all(selectedLicenses.map(license =>
        axios.delete(`${getApiBaseUrl()}/api/v1/licenses/${license.customerId}`, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      ));
      setSelectedLicenses([]);
      await fetchLicenses().catch(() => {});
    } catch (error) {
      genericErrorHandler(error, 'Failed to remove selected licenses.');
    }
  };

  const handleEditLicense = (license, index) => {
    setLicenseData({
      customerId: license.customerId,
      count: license.count,
      startDate: new Date(license.startDate),
      endDate: new Date(license.endDate),
      status: license.status,
      meta: license.meta || []
    });
    setInitialData({ ...license, startDate: new Date(license.startDate), endDate: new Date(license.endDate) });
    setEditLicenseIndex(index);
  };

  const handleUpdateLicense = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${getApiBaseUrl()}/api/v1/licenses`, licenseData, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
      setEditLicenseIndex(null);
      setLicenseData(EMPTY_STATE);
      await fetchLicenses().catch(() => {});
    } catch (error) {
      genericErrorHandler(error, 'Failed to update license.');
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleSelectLicense = (license) => {
    setSelectedLicenses((prevSelected) =>
      prevSelected.includes(license)
        ? prevSelected.filter((l) => l !== license)
        : [...prevSelected, license]
    );
  };

  const handleSelectAllLicenses = () => {
    if (selectedLicenses.length === filteredLicenses.length) {
      setSelectedLicenses([]);
    } else {
      setSelectedLicenses(filteredLicenses);
    }
  };

  return (
    <div className="container mt-3">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <button className="btn btn-secondary" onClick={handleLogout}>Logout</button>
        <h2>Welcome {username} | IAR Licenses:</h2>
        <button className={`btn btn-primary`} onClick={handleGoToCustomers}>
          Go to Customers Dashboard
        </button>
        <button className={`btn btn-primary ${showAddLicense ? 'invisible' : ''}`} onClick={() => {
          setLicenseData(EMPTY_STATE);
          setShowAddLicense(!showAddLicense)
        }}>
          Add License
        </button>
      </div>
      {showAddLicense && (
        <LicenseForm
          licenseData={licenseData}
          setLicenseData={setLicenseData}
          handleSubmit={handleAddLicense}
          setShowForm={setShowAddLicense}
          isEdit={false}
          initialData={initialData}
        />
      )}
      {editLicenseIndex !== null && (
        <LicenseForm
          licenseData={licenseData}
          setLicenseData={setLicenseData}
          handleSubmit={handleUpdateLicense}
          setShowForm={() => setEditLicenseIndex(null)}
          isEdit={true}
          initialData={initialData}
        />
      )}
      <div className="mb-3">
        <h4>Filter Licenses</h4>
        <div className="d-flex">
          <input type="text" name="customerId" placeholder="Customer ID" value={filters.customerId} onChange={handleFilterChange} className="form-control mr-2" />
          <input type="text" name="status" placeholder="Status" value={filters.status} onChange={handleFilterChange} className="form-control mr-2" />
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <button className="btn btn-danger" onClick={handleRemoveSelectedLicenses} disabled={selectedLicenses.length === 0}>
          Delete Selected
        </button>
        <span>{selectedLicenses.length} selected</span>
      </div>
      <LicenseTable
        licenses={filteredLicenses}
        handleEditLicense={handleEditLicense}
        handleRemoveLicense={handleRemoveLicense}
        selectedLicenses={selectedLicenses}
        handleSelectLicense={handleSelectLicense}
        handleSelectAllLicenses={handleSelectAllLicenses}
      />
    </div>
  );
}

export default Dashboard;
